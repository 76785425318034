import React, { Component } from "react";
import { SearchBuilding } from "./SearchBuilding";
import StatsBar from "./BlockStats";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad, faWater, faChartBar, faSearchLocation } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Batiment } from '../assets/icons/ico_flodedhouse.svg';
import { ReactComponent as SacdeSable } from '../assets/icons/ico_sacsable.svg';
import * as actionCreators from "../store/actions";
import { connect } from "react-redux";


export class BottomPanel extends Component {
    render() {
        const btnPdf = document.getElementById("pdfBtn");
        const btnAlert = document.getElementById("alertBtn");
        if (btnPdf !== null && btnAlert !== null) {
            if (this.props.currentDataExport.length === 0) {
                btnPdf.style.display = "none";
                btnAlert.style.display = "none";
            } else {
                btnPdf.style.display = "block";
                btnAlert.style.display = "block";
            }
        }
        return (
            <div className="panel">
                <div id="btn-toggle-layer">
                    <div className="btn-container-layer">

                        <div className="left-panel-stat-container stats">
                            <button
                                id="btnStats"
                                data-title="Statistics"
                                className={this.props.toggleStats ? "btnTrue" : "btnFalse"}
                                onClick={this.props.onStatsToggle}
                            >
                                <FontAwesomeIcon icon={faChartBar}/>
                            </button>
                        </div>

                        <div className="left-panel-stat-container">
                            <button
                                id="btnWater"
                                data-title="Water height"
                                className={this.props.toggleWater ? "btnTrue" : "btnFalse"}
                                onClick={this.props.onWaterToggle}
                            >
                                <FontAwesomeIcon icon={faWater}/>
                            </button>
                        </div>

                        <div className="left-panel-stat-container">
                            <button
                                id="btnBuilding"
                                data-title="Buildings"
                                className={this.props.toggleBuilding ? "btnTrue" : "btnFalse"}
                                onClick={this.props.onBuildingToggle}
                            >
                                <Batiment/>
                            </button>
                        </div>

                        <div className="left-panel-stat-container">
                            <button
                                id="btnStreet"
                                data-title="Roads"
                                className={this.props.toggleStreet ? "btnTrue" : "btnFalse"}
                                onClick={this.props.onStreetToggle}
                            >
                                <FontAwesomeIcon icon={faRoad}/>
                            </button>
                        </div>

                        <div className="left-panel-stat-container">
                            <button
                                id="btnBag"
                                data-title="Sandbags"
                                className={this.props.toggleBag ? "btnTrue" : "btnFalse"}
                                onClick={this.props.onBagToggle}
                            >
                                <SacdeSable/>
                            </button>
                        </div>

                        <div className="left-panel-stat-container">
                            <button
                                id="btnIncertMin"
                                data-title="Uncertainty (Min)"
                                className={this.props.toggleMin ? "btnTrue" : "btnFalse"}
                                onClick={this.props.onMinToggle}
                            >
                                min
                            </button>
                        </div>

                        <div className="left-panel-stat-container">
                            <button
                                id="btnIncertMax"
                                data-title="Uncertainty (Max)"
                                className={this.props.toggleMax ? "btnTrue" : "btnFalse"}
                                onClick={this.props.onMaxToggle}
                            >
                                max
                            </button>
                        </div>

                        <div className="left-panel-stat-container search">
                            <button
                                id="btn-search-ctrl"
                                data-title="Search for buildings"
                                className={this.props.toggleSearch ? "btnTrue" : "btnFalse"}
                                onClick={this.props.onSearchToggle}
                            >
                                <FontAwesomeIcon icon={faSearchLocation}/>
                            </button>
                        </div>

                        <SearchBuilding toggle={this.props.toggleSearch}
                                        currentDataExport={this.props.currentDataExport}/>

                    </div>
                </div>
                <StatsBar
                    scenarioDepart={this.props.scenarioDepart}
                    labels={this.props.labels}
                    dataIsole={this.props.dataIsole}
                    dataSS={this.props.dataSS}
                    dataPrem={this.props.dataPrem}
                    dataTot={this.props.dataTot}
                    dataEvac={this.props.dataEvac}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        toggleWater: state.water,
        toggleBuilding: state.building,
        toggleStreet: state.street,
        toggleBag: state.bag,
        toggleMin: state.min,
        toggleMax: state.max,
        toggleStats: state.stats,
        toggleSearch: state.search,
        currentDataExport: state.dataExport
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onWaterToggle: () => dispatch(actionCreators.toggleWater()),
        onBuildingToggle: () => dispatch(actionCreators.toggleBuilding()),
        onStreetToggle: () => dispatch(actionCreators.toggleStreet()),
        onBagToggle: () => dispatch(actionCreators.toggleBag()),
        onMinToggle: () => dispatch(actionCreators.toggleMin()),
        onMaxToggle: () => dispatch(actionCreators.toggleMax()),
        onStatsToggle: () => dispatch(actionCreators.toggleStats()),
        onSearchToggle: () => dispatch(actionCreators.toggleSearch())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomPanel);