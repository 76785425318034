import React, { useState } from "react";
import "./Legende.css";
import LegendIcon from 'calcite-ui-icons-react/LegendIcon';
import LegendPic from "../assets/legende_bold_EN.png"

export const Legende = () => {
    const [ legendeState, legendeSetState] = useState({
        toggleLegende: false
    });
    const legendeToggle = () => {
        legendeSetState({
            toggleLegende: !legendeState.toggleLegende
        })
    };
    return (
        <>
            <button id="btn-toggle-legende" onClick={legendeToggle}>
                {/*<FontAwesomeIcon icon={faInfoCircle} size="2x" color={"var(--color-bg-stats)"}/>*/}
                <LegendIcon/>
            </button>

            <div id="div-legende" style={{display: legendeState.toggleLegende ? 'block' : 'none' }}>
              {/*<img src={LegendPic} alt="Legende" height="383" width="342"/>*/}
              <img src={LegendPic} alt="Legende"/>
            </div>
        </>
    );
};